import { useState } from 'react';
import { graphql } from '../gql/index.ts';
import { useQuery } from 'urql';
import { SeminarCard } from './SeminarCard.tsx';
import { Pagination } from './Pagination.tsx';
import { usePublicCompany } from './hooks/usePublicCompany.ts';
import { getFormattedSeminarDate } from './util.ts';
import { ErrorPage } from './ErrorPage.tsx';
import { PublicMenuSidebar } from './PublicMenuSidebar.tsx';
import { useSearchParameter } from './hooks/useSearchParameter.ts';

const PublicSeminarsQuery = graphql(/* GraphQL */ `
  query getPublicSeminars(
    $companyId: String!
    $categoryId: String
    $limit: Int!
    $skip: Int!
    $searchTerm: String
  ) {
    publicSeminars(
      companyId: $companyId
      categoryId: $categoryId
      limit: $limit
      skip: $skip
      searchTerm: $searchTerm
    ) {
      seminars {
        id
        title
        subtitle
        seminarNumber
        dateText
        description
        plainDescription
        locationText
        startDate
        endDate
        signUps {
          canceled
        }
        currentUserIsLecturer
        lecturers {
          name
        }
        categories {
          name
        }
      }
      count
    }
  }
`);

const pageSize = 5;

interface PublicSeminarListProps {
  companyId: string;
  categoryId?: string;
}

export const PublicSeminarList: React.FC<PublicSeminarListProps> = ({
  companyId,
  categoryId,
}) => {
  const searchStringFromUrl = useSearchParameter();
  const [currentPage, setCurrentPage] = useState(0);

  const [prevCategoryId, setPrevCategoryId] = useState<string | undefined>(
    categoryId,
  );
  const [prevSearchString, setPrevSearchString] = useState<string | null>(
    searchStringFromUrl,
  );
  if (prevCategoryId !== categoryId) {
    setPrevCategoryId(categoryId);
    setCurrentPage(0);
  }
  if (prevSearchString !== searchStringFromUrl) {
    setPrevSearchString(searchStringFromUrl);
    setCurrentPage(0);
  }
  const [publicSeminarsQueryResult] = useQuery({
    query: PublicSeminarsQuery,
    variables: {
      companyId,
      categoryId,
      limit: pageSize,
      skip: currentPage * pageSize,
      searchTerm: searchStringFromUrl,
    },
    pause: companyId === undefined || companyId === null,
  });
  const { data } = publicSeminarsQueryResult;
  const { publicSeminars } = data || {};
  const [publicCompany] = usePublicCompany();

  const showSidebar =
    publicCompany?.functionalSettings?.searchActive ||
    Boolean(publicCompany?.sites?.length) ||
    Boolean(publicCompany?.categories?.length);

  return (
    publicSeminarsQueryResult.data && (
      <>
        {showSidebar && <PublicMenuSidebar publicCompany={publicCompany} />}
        <div
          className={`${showSidebar ? 'md:col-span-9 md:col-start-4' : 'md:col-span-12'} col-span-1 flex flex-col gap-4`}
        >
          {publicSeminars?.count ? (
            <>
              <h2>Nächste Seminare</h2>
              {publicSeminars?.seminars.map((seminar) => {
                return (
                  <SeminarCard
                    key={seminar?.id}
                    instructors={
                      seminar?.lecturers
                        ?.map((lecturer) => lecturer.name)
                        .filter(Boolean) as string[]
                    }
                    id={seminar?.seminarNumber}
                    title={seminar?.title}
                    time={getFormattedSeminarDate(
                      seminar.startDate,
                      seminar.endDate,
                      seminar.dateText,
                    )}
                    description={seminar?.plainDescription}
                    subtitle={seminar?.subtitle}
                    location={seminar?.locationText}
                    category={
                      seminar?.categories?.find((c) => Boolean(c.name.trim()))
                        ?.name
                    }
                    titleLink={'/seminar/' + seminar?.id}
                    isSignedUp={Boolean(
                      seminar.signUps.filter((s) => !s.canceled).length,
                    )}
                    isSignUpCanceled={
                      Boolean(seminar.signUps.length) &&
                      !seminar.signUps.some((s) => !s.canceled)
                    }
                    isLecturer={Boolean(seminar.currentUserIsLecturer)}
                  />
                );
              })}
            </>
          ) : (
            <ErrorPage text="Keine Seminare gefunden" />
          )}
        </div>
        {Boolean(publicSeminars?.count) && (
          <div className="col-span-1 md:col-span-9 md:col-start-4">
            <Pagination
              onChange={(newPage) => {
                setCurrentPage(newPage);
                window.scrollTo({ top: 0, left: 0 });
              }}
              value={currentPage}
              countPages={Math.ceil((publicSeminars?.count ?? 0) / pageSize)}
            />
          </div>
        )}
      </>
    )
  );
};
