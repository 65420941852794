import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'urql';
import { UpdateSeminarDto } from '../../gql/graphql.ts';
import { graphql } from '../../gql';
import { InputStateWrapper as HookedInput } from '../form-components/InputStateWrapper.tsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'wouter';
import { Button } from '@progress/kendo-react-buttons';
import { PageTitle } from '../PageTitle.tsx';
import { Fieldset } from '../form-components/Fieldset.tsx';
import { CheckboxStateWrapper } from '../form-components/CheckboxStateWrapper.tsx';
import { DateTimePickerStateWrapper } from '../form-components/DateTimePickerStateWrapper.tsx';
import { HeroIcon } from '../../shared/HeroIcon.tsx';
import { NumericInputStateWrapper } from '../form-components/NumericInputStateWrapper.tsx';
import { EditorStateWrapper } from '../form-components/EditorStateWrapper.tsx';
import { cloneAndRemoveTypename } from '../../public/util.ts';

interface SeminarEditPageProps {
  companyId: string;
  seminarId?: string;
  btsSeminarId?: string;
}

const SeminarQuery = graphql(/* GraphQL */ `
  query seminar(
    $companyId: String!
    $seminarId: String
    $btsSeminarId: String
  ) {
    seminar(
      companyId: $companyId
      seminarId: $seminarId
      btsSeminarId: $btsSeminarId
    ) {
      id
      title
      startDate
      endDate
      registrationDate
      canceled
      locationText
      price
      price2
      price3
      price4
      priceText
      priceText2
      priceText3
      priceText4
      minParticipants
      maxParticipants
      currentParticipants
      description
      description2
      description3
      description4
      description5
      visible
      btsLocked
      passwords
    }
  }
`);

const UpdateSeminarMutation = graphql(/* GraphQL */ `
  mutation updateSeminar(
    $companyId: String!
    $seminarId: String!
    $updatedValues: UpdateSeminarDto!
  ) {
    updateSeminar(
      companyId: $companyId
      seminarId: $seminarId
      updateValues: $updatedValues
    )
  }
`);

export const SeminarEditPage: React.FC<SeminarEditPageProps> = ({
  companyId,
  seminarId,
  btsSeminarId,
}) => {
  // TODO: Error handling
  const [{ data, fetching }] = useQuery({
    query: SeminarQuery,
    variables: { companyId, seminarId, btsSeminarId },
    pause:
      companyId === undefined ||
      companyId === null ||
      (!seminarId && !btsSeminarId),
  });

  const { control, handleSubmit, reset, formState } = useForm<UpdateSeminarDto>(
    {
      mode: 'onChange',
      reValidateMode: 'onChange',
    },
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'passwords',
  });

  useEffect(() => {
    if (!seminarId && btsSeminarId && data?.seminar.id) {
      window.history.replaceState(
        null,
        '',
        `/admin/seminars/edit/${data?.seminar.id}`,
      );
    }
  }, [seminarId, btsSeminarId, data?.seminar.id]);

  useEffect(() => {
    if (!fetching && data?.seminar) {
      const withoutTypename =
        data?.seminar && cloneAndRemoveTypename(data?.seminar);
      reset({
        ...withoutTypename,
        startDate: new Date(data.seminar.startDate),
        endDate: new Date(data.seminar.endDate),
        registrationDate:
          data.seminar.registrationDate &&
          new Date(data.seminar.registrationDate),
      });
    }
  }, [fetching]);

  const [, updateSeminar] = useMutation(UpdateSeminarMutation);
  const [, setLocation] = useLocation();

  const onFormSubmit = (value: UpdateSeminarDto) => {
    if (seminarId) {
      updateSeminar({ companyId, seminarId, updatedValues: value }).then(() => {
        setLocation('/seminars');
      });
    }
  };

  const { isValid, isDirty } = formState;

  return (
    <>
      <PageTitle title="Seminar bearbeiten">
        <a
          href={`/seminar/${seminarId}`}
          target="_blank"
          className="flex items-center gap-1.5 text-sm hover:underline"
        >
          Zur öffentlichen Seite{' '}
          <HeroIcon name="ArrowTopRightOnSquare" className="h-5 w-5 shrink-0" />
        </a>
      </PageTitle>
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend={`Allgemein`}
          description="Basisinformationen rund um das Seminar"
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <CheckboxStateWrapper
              name={'canceled'}
              label={'Abgesagt'}
              control={control}
            />
            <CheckboxStateWrapper
              name={'visible'}
              label={'Sichtbar'}
              control={control}
            />
            <CheckboxStateWrapper
              name={'btsLocked'}
              label={'BTS-Aktualisierungen sperren'}
              control={control}
            />
            <HookedInput
              name={'title'}
              label={'Seminartitel'}
              control={control}
              readOnly
            />
            <div className="grid grid-cols-3 gap-x-6">
              <DateTimePickerStateWrapper
                name={'startDate'}
                label={'Beginn'}
                control={control}
              />
              <DateTimePickerStateWrapper
                name={'endDate'}
                label={'Ende'}
                control={control}
              />
              <DateTimePickerStateWrapper
                name={'registrationDate'}
                label={'Anmeldeschluss'}
                control={control}
              />
            </div>

            <HookedInput
              name={'locationText'}
              label={'Ort'}
              rules={{ required: 'Dieses Feld muss ausgefüllt werden!' }}
              control={control}
            />
            <div className="grid grid-cols-3 gap-x-6">
              <NumericInputStateWrapper
                name={'minParticipants'}
                label={'Minimale Teilnehmeranzahl'}
                control={control}
              />
              <NumericInputStateWrapper
                name={'maxParticipants'}
                label={'Maximale Teilnehmeranzahl'}
                control={control}
              />
              <NumericInputStateWrapper
                name={'currentParticipants'}
                label={'Aktuelle Teilnehmeranzahl'}
                control={control}
              />
            </div>
          </div>
        </Fieldset>
        <Fieldset
          legend={`Preise`}
          description="Modifizieren Sie die verschiedenen Preise und ihre Beschreibungen"
        >
          <div className="grid grid-cols-[30%_1fr] gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <NumericInputStateWrapper
              name={'price'}
              format={'c2'}
              label={'Preis'}
              control={control}
            />
            <HookedInput
              name={'priceText'}
              label={'Preis Beschreibung'}
              control={control}
            />
            <NumericInputStateWrapper
              name={'price2'}
              label={'Preis 2'}
              format={'c2'}
              control={control}
            />
            <HookedInput
              name={'priceText2'}
              label={'Preis Beschreibung 2'}
              control={control}
            />
            <NumericInputStateWrapper
              name={'price3'}
              label={'Preis 3'}
              format={'c2'}
              control={control}
            />
            <HookedInput
              name={'priceText3'}
              label={'Preis Beschreibung 3'}
              control={control}
            />
            <NumericInputStateWrapper
              name={'price4'}
              label={'Preis 4'}
              format={'c2'}
              control={control}
            />
            <HookedInput
              name={'priceText4'}
              label={'Preis Beschreibung 4'}
              control={control}
            />
          </div>
        </Fieldset>
        <Fieldset
          legend={`Beschreibungen und Webseiten-Texte`}
          description="Die Beschreibungen tauchen auf der öffentlichen Webseite für Teilnehmer und Interessenten auf."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <EditorStateWrapper
              name={'description'}
              label={'Beschreibung 1'}
              control={control}
            />
            <EditorStateWrapper
              name={'description2'}
              label={'Beschreibung 2'}
              control={control}
            />
            <EditorStateWrapper
              name={'description3'}
              label={'Beschreibung 3'}
              control={control}
            />
            <EditorStateWrapper
              name={'description4'}
              label={'Beschreibung 4'}
              control={control}
            />
            <EditorStateWrapper
              name={'description5'}
              label={'Beschreibung 5'}
              control={control}
            />
          </div>
        </Fieldset>

        <Fieldset
          legend={`Passwörter`}
          description="Hier können Sie ein oder mehrere Passwörter definieren, mit denen Teilnehmer sich für das Seminar anmelden können. Ohne eines der Passwörter ist dann keine Anmeldung mehr möglich."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            {fields.map((field, i) => (
              <div key={i} className={'flex items-end justify-between gap-4'}>
                <HookedInput
                  wrapperClassName={'flex-1'}
                  key={field.id}
                  name={`passwords.${i}`}
                  control={control}
                  label={`Passwort ${i + 1}`}
                  rules={{ required: 'Passwort muss ausgefüllt sein!' }}
                />
                <Button
                  className="h-[38px] !shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
                  type={'button'}
                  fillMode={'outline'}
                  themeColor={'secondary'}
                  onClick={() => remove(i)}
                  title="Entfernen"
                >
                  <HeroIcon name="Trash" className="block h-5 w-5" />
                </Button>
              </div>
            ))}
            <Button
              type={'button'}
              themeColor={'secondary'}
              className="self-start !shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
              onClick={() => append('')}
              title="Passwort hinzufügen"
            >
              <HeroIcon name="PlusCircle" className="block h-5 w-5" />
              <div>Passwort hinzufügen</div>
            </Button>
          </div>
        </Fieldset>

        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
