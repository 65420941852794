import React, { Fragment } from 'react';
import { Link } from 'wouter';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { Button } from '@progress/kendo-react-buttons';

interface SeminarPrice {
  price?: number | null;
  priceText?: string | null;
  price2?: number | null;
  priceText2?: string | null;
  price3?: number | null;
  priceText3?: string | null;
  price4?: number | null;
  priceText4?: string | null;
}

export interface SeminarCardProps {
  title?: string | null;
  subtitle?: string | null;
  category?: string | null;
  description?: string | null;
  location?: string | null;
  time?: string | null;
  instructors?: string | Array<string> | null;
  id?: string | null;
  titleLink?: string;
  singleView?: boolean;
  price?: SeminarPrice;
  signUpLink?: string;
  isSignedUp?: boolean;
  isSignUpCanceled?: boolean;
  isLecturer?: boolean;
  signUpLinkCaption?: string;
  signUpLinkLogo?: string;
  cancelSignUpCaption?: string;
  cancelSignUpLogo?: string;
  onCancelSignUpClick?: () => void;
  tabbed?: boolean;
}

const truncateStringAtWhitespace = (
  str: string,
  maxLen: number,
  separator = ' ',
) => {
  if (str.length <= maxLen) return str;
  return str.substring(0, str.lastIndexOf(separator, maxLen)) + '...';
};

export const SeminarCard: React.FC<SeminarCardProps> = ({
  title,
  category,
  description,
  location,
  time,
  instructors,
  id,
  titleLink,
  singleView,
  subtitle,
  signUpLink,
  isSignedUp,
  isSignUpCanceled,
  isLecturer,
  signUpLinkCaption = 'Anmelden',
  signUpLinkLogo = 'AcademicCap',
  cancelSignUpCaption = 'Abmelden',
  cancelSignUpLogo = 'XMark',
  onCancelSignUpClick,
  tabbed,
}) => {
  const isMultipleInstructors =
    Array.isArray(instructors) && instructors.length > 1;

  return (
    <div
      className={`flex w-full flex-col bg-white ${tabbed ? '' : 'relative rounded-md border border-gray-200 p-6 md:p-8'}`}
    >
      {isSignedUp && !isLecturer && (
        <span className="absolute right-0 top-0 flex items-center gap-2 rounded-bl bg-gray-200 p-2">
          Sie sind angemeldet{' '}
          <HeroIcon name="ShieldCheck" className="h-5 w-5 text-black" />
        </span>
      )}
      {isSignUpCanceled && !isLecturer && (
        <span className="absolute right-0 top-0 flex items-center gap-2 rounded-bl bg-gray-200 p-2">
          Sie sind abgemeldet{' '}
          <HeroIcon name="ShieldExclamation" className="h-5 w-5 text-black" />
        </span>
      )}
      {isLecturer && (
        <span className="absolute right-0 top-0 flex items-center gap-2 rounded-bl bg-gray-200 p-2">
          Sie sind Dozent{' '}
          <HeroIcon name="AcademicCap" className="h-5 w-5 text-black" />
        </span>
      )}
      {category && (
        <span className="text-sm font-medium leading-6 text-bts-theme-primary">
          {category}
        </span>
      )}
      {title && (
        <h2 className={`${category ? 'mt-4' : ''}`}>
          {titleLink ? <Link href={titleLink}>{title}</Link> : title}
        </h2>
      )}
      {subtitle && (
        <h3 className="text-bts-theme-text/70 text-lg font-normal">
          {subtitle}
        </h3>
      )}
      {singleView && id ? (
        <div className="flex justify-between">
          <InlineDetail
            icon="Hashtag"
            label="Seminarnummer"
            caption={id}
            title={'Ort'}
          />
        </div>
      ) : (
        (location || time) && (
          <div className="flex justify-between">
            {location && (
              <InlineDetail icon="MapPin" caption={location} title={'Ort'} />
            )}
            {time && (
              <InlineDetail
                icon="Clock"
                caption={time}
                title={'Datum und Uhrzeit'}
              />
            )}
          </div>
        )
      )}
      {(Boolean(description) || Boolean(signUpLink)) &&
        (singleView ? (
          <>
            <article
              className={`prose prose-zinc max-w-none gap-4 text-base leading-7 ${category || title || subtitle || id || location || time ? 'mt-6' : ''}`}
              {...(singleView
                ? {
                    dangerouslySetInnerHTML: {
                      __html: description as string,
                    },
                  }
                : {})}
            ></article>
            {signUpLink && (
              <div className="mt-8 space-x-2">
                <Link
                  href={signUpLink}
                  // className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md text-red"
                  className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md text-red !border-bts-theme-primary !bg-bts-theme-primary hover:!border-bts-theme-primary-darker hover:!bg-bts-theme-primary-darker"
                >
                  {signUpLinkLogo && (
                    <HeroIcon
                      name={signUpLinkLogo}
                      className="inline-block h-5 w-5"
                    />
                  )}
                  {signUpLinkCaption}
                </Link>
                {onCancelSignUpClick && (
                  <Button
                    onClick={onCancelSignUpClick}
                    themeColor={'secondary'}
                    className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
                  >
                    {cancelSignUpLogo && (
                      <HeroIcon
                        name={cancelSignUpLogo}
                        className="inline-block h-5 w-5"
                      />
                    )}
                    {cancelSignUpCaption}
                  </Button>
                )}
              </div>
            )}
          </>
        ) : (
          <p
            className="mt-6 text-base leading-7 text-zinc-500"
            dangerouslySetInnerHTML={{
              __html: truncateStringAtWhitespace(
                description as string,
                500,
                ' ',
              ),
            }}
          />
        ))}
      {!singleView && (instructors || id) && (
        <dl className="mt-8 divide-y border-y">
          {instructors && instructors?.length > 0 && (
            <div className="flex items-start justify-between py-2">
              <dt className="flex items-center gap-1 font-semibold">
                <HeroIcon name="UserCircle" className="h-5 w-5 shrink-0" />
                {`Referent${isMultipleInstructors ? 'en' : ''}:`}
              </dt>
              <dd className="text-right">
                {isMultipleInstructors
                  ? instructors.map((name, i) => (
                      <Fragment key={i}>
                        {name}
                        <br />
                      </Fragment>
                    ))
                  : instructors}
              </dd>
            </div>
          )}
          {id && (
            <div className="flex flex-wrap items-center justify-between py-2">
              <dt className="flex items-center gap-1 font-semibold">
                <HeroIcon
                  name="InformationCircle"
                  className="h-5 w-5 shrink-0"
                />
                Seminarnummer:
              </dt>
              <dd>{id}</dd>
            </div>
          )}
        </dl>
      )}
    </div>
  );
};

interface InlineDetailProps {
  icon: string;
  caption: string;
  title?: string;
  label?: string;
}

const InlineDetail: React.FC<InlineDetailProps> = ({
  icon,
  caption,
  title,
  label,
}) => {
  return (
    <div className="mt-4 flex items-center text-zinc-400" title={title}>
      <HeroIcon name={icon} className="mr-1 h-5 w-5 shrink-0" />
      <div className="text-sm">
        {label && <span>{`${label}: `}</span>}
        {caption}
      </div>
    </div>
  );
};
