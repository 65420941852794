import {
  Grid,
  GridColumn,
  GridFilterChangeEvent,
  GridNoRecords,
  GridToolbar,
} from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import {
  GetPublicSeminarQuery,
  GetPublicSignUpsSeminarQuery,
} from '../gql/graphql.ts';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import {
  CompositeFilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';
import { cloneAndRemoveTypename } from './util.ts';

interface Props {
  dataSignUp?: GetPublicSignUpsSeminarQuery;
  dataPublicSeminar?: GetPublicSeminarQuery;
  emailCurrentUser?: string;
}

export const PublicSeminarParticipantsTab: React.FC<Props> = ({
  dataPublicSeminar,
  dataSignUp,
  emailCurrentUser,
}) => {
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });

  const [filter, setFilter] = useState<CompositeFilterDescriptor>({
    logic: 'and',
    filters: [],
  });

  const filteredParticipantsData = filterBy(
    dataSignUp?.signUpsForSeminar
      ? cloneAndRemoveTypename(dataSignUp?.signUpsForSeminar)
      : [],
    filter,
  );

  return (
    <Grid
      className="h-auto !border-0 [&_.k-grid-norecords-template]:border-0"
      data={filteredParticipantsData.slice(page.skip, page.take + page.skip)}
      onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
      filterable={true}
      scrollable={'none'}
      filter={filter}
      take={page?.take}
      skip={page?.skip}
      total={filteredParticipantsData.length}
      onPageChange={(event) => {
        setPage(event.page);
      }}
      pageable
    >
      {Boolean(dataPublicSeminar?.publicSeminar?.currentUserIsLecturer) &&
        filteredParticipantsData.length > 0 && (
          <GridToolbar className="!flex !justify-end">
            <a
              href={`mailto:${emailCurrentUser}?bcc=${filteredParticipantsData.map((d) => d.email).join(',')}`}
              className="k-button k-button-sm k-rounded-md k-button-outline k-button-outline-secondary"
              title="Feld bearbeiten"
            >
              <HeroIcon name="Envelope" className="block h-5 w-5" />
              E-Mail an alle Teilnehmer
            </a>
          </GridToolbar>
        )}
      <GridNoRecords>Keine Teilnehmer gefunden</GridNoRecords>
      <GridColumn field="email" title="E-Mail" />
      <GridColumn field="firstName" title="Vorname" />
      <GridColumn field="lastName" title="Nachname" />
    </Grid>
  );
};
