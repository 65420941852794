import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import React, { useState } from 'react';
import { SeminarSignUp } from '../gql/graphql.ts';
import { HeroIcon } from '../shared/HeroIcon.tsx';

interface Props {
  signUps: SeminarSignUp[];
  onClickCancel?: (signUpId: number) => void;
}

export const PublicSeminarSignUpsTab: React.FC<Props> = ({
  signUps,
  onClickCancel,
}) => {
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });

  return (
    <Grid
      className="h-auto !border-0 [&_.k-grid-norecords-template]:border-0"
      data={signUps.slice(page.skip, page.take + page.skip)}
      scrollable={'none'}
      take={page?.take}
      skip={page?.skip}
      total={signUps.length}
      onPageChange={(event) => {
        setPage(event.page);
      }}
      pageable
    >
      <GridColumn field="firstName" title="Vorname" />
      <GridColumn field="lastName" title="Nachname" />
      <GridColumn field="email" title="E-Mail" />
      <GridColumn
        width={60}
        cell={(props) => (
          <td className={'k-table-td'}>
            {onClickCancel && (
              <Button
                fillMode={'outline'}
                type={'button'}
                themeColor={'secondary'}
                disabled={props.dataIndex === 0}
                onClick={() => {
                  onClickCancel(props.dataItem.signUpId);
                }}
                title="Block nach oben schieben"
              >
                <HeroIcon name="Trash" className="block h-5 w-5" />
              </Button>
            )}
          </td>
        )}
      />
    </Grid>
  );
};
