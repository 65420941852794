import { Button } from '@progress/kendo-react-buttons';
import React, { useState } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { Dialog } from '@progress/kendo-react-dialogs';
import dayjs from 'dayjs';

interface Props {
  onClose: () => void;
  startDate: Date;
  onClickCancel: () => void;
}

export const PublicSeminarCancelSignUpModal: React.FC<Props> = ({
  onClose,
  startDate,
  onClickCancel,
}) => {
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  return (
    <Dialog
      autoFocus={true}
      width={650}
      title={'Seminarabmeldung?'}
      onClose={onClose}
    >
      <div className="flex flex-col gap-6">
        <p className="prose prose-headings:font-medium prose-h1:text-3xl prose-h2:text-2xl prose-h3:text-xl prose-h4:text-lg">
          Diese Seminar findet in {dayjs(startDate).diff(dayjs(), 'days')} Tagen
          statt. Bitte beachten Sie, dass eventuell eine Stornogebühr anfällt.
        </p>
        <div className={'flex flex-row gap-2'}>
          <Button
            themeColor={'primary'}
            disabled={isCancelLoading}
            className={
              '[&>span.k-button-text]:flex [&>span.k-button-text]:items-center'
            }
            title="Abmelden"
            onClick={(e) => {
              e.preventDefault();
              setIsCancelLoading(true);
              onClickCancel();
            }}
          >
            {isCancelLoading && (
              <Loader type={'pulsing'} className={'!text-white'} />
            )}
            Abmelden
          </Button>
          <Button
            themeColor={'secondary'}
            className={
              '[&>span.k-button-text]:flex [&>span.k-button-text]:items-center'
            }
            title="Abbrechen"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            Abbrechen
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
